
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.home-faq-item {
  margin: 0 0 20px;
}
.home-faq-header {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid $line-on-background-dark-or-gap;
}
.home-faq-title {
  flex: 1;
  font-weight: bold;
  padding: 15px 0 15px 15px;
  min-height: 47px;
}
.home-faq-icon {
  width: 30px;
  text-align: center;
  font-size: 30px;
  line-height: 47px;
  color: $clickable-elements;
}
.home-faq-content {
  padding: 15px;
  color: $description-text;

  a {
    color: $clickable-elements;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
