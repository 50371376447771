
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.w-footer {
  padding: 30px 0 0;
  background-color: $white;
  box-shadow: inset 0 2px 4px 0 rgba(#000, 0.07);

  ul {
    margin: 0;
    padding: 0;
  }

  .link svg:hover {
    color: $clickable-elements;
  }

  .link {
    text-decoration: none;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease;
    cursor: pointer;
  }

  .u-bg-gray {
    background-color: $background;
  }

  .language {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .language__item {
    @include transition;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    padding: 5px 10px;
    border-radius: 21px;
    background: $white;
    color: $clickable-elements;
    font-size: 12px;
    border: none;
    width: 75px;
    height: 25px;
    outline: none;
    @include media("<=laptop") {
      outline: none;
    }
    &:focus-visible {
      outline: auto;
      transition: none;
    }
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.is-active,
    &:hover {
      background-color: $clickable-elements;
      color: $white;
    }
  }
}

.w-footer__container {
  flex-direction: column;
}

.w-footer__row {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  @include media(">tablet") {
    flex-direction: row;
  }
}

.w-footer__top-row {
  padding-bottom: 20px;
}

.w-footer__navs {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.w-footer__nav {
  margin-bottom: 30px;

  @include media(">tablet") {
    margin-bottom: 20px;
  }

  &:last-of-type {
    @include media(">tablet") {
      margin-bottom: 0;
    }
  }
}

.w-footer__info-item,
.w-footer__item {
  .link {
    display: block;
    color: $land-bottom-clickable-elements;

    &:hover,
    &.is-active {
      color: $clickable-elements;
    }
  }
}

.w-footer__header {
  margin: 0 0 10px;
  color: $headers-and-simple-text;
  text-align: center;
  font-weight: 600;

  @include media(">tablet") {
    margin: 0 0 5px;
    text-align: left;
  }
}

.w-footer__list {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: center;

  @include media(">tablet") {
    align-items: flex-start;
    flex-direction: row;
  }
}

.w-footer__item {
  margin-bottom: 6px;

  .link {
    &:hover {
      color: $clickable-elements;
    }
  }

  @include media(">tablet") {
    margin-right: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.w-footer__umbrella {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media(">tablet") {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.w-footer__copyrights {
  @include media(">tablet") {
    margin: 0 30px;
  }
}

.w-footer__text {
  max-width: 280px;
  margin: 0 0 14px;
  text-align: center;

  @include media(">tablet") {
    margin: 0 0 20px;
    text-align: right;
  }
}

.w-footer__logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 20px;
  html[dir="rtl"] & {
    // WebpackRTLPlugin will change it to ltr, so we will have the same direction of logos for both languages
    direction: rtl;
  }
}

.w-footer__logo {
  &.w-footer__logo--margin {
    margin-left: 20px;
  }
}

.w-footer__logo-img {
  max-height: 35px;

  @include media(">tablet") {
    max-height: 60px;
  }
}

.w-footer__bottom-row {
  align-items: center;
  padding: 20px 0;
}
.w-footer__info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column wrap;
  font-size: 11px;

  .w-footer__copyrights {
    color: $land-bottom-clickable-elements;
    font-weight: 500;
  }

  @include media(">tablet") {
    flex-direction: row;
  }
}

.w-footer__logo-product {
  width: 100px;
  margin-bottom: 10px;

  span {
    display: block;
  }

  @include media(">tablet") {
    justify-content: flex-start;
    margin-right: 10px;
  }
}

.w-footer__logo-product-img {
  width: 100%;
  background-size: cover;
  max-height: 38px;
}

.w-footer__info-list {
  display: flex;
  justify-content: center;
  margin: 15px 0;
  list-style: none;

  li {
    margin: 0 10px;
  }

  @include media(">tablet") {
    justify-content: flex-start;
    margin: 0;
  }
}

.w-footer__copyright {
  color: $land-bottom-clickable-elements;
}

.w-footer__languages {
  margin: 20px 0;

  @include media(">tablet") {
    margin: 15px 0;
  }
}

.social-icons {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

  @include media(">tablet") {
    justify-content: flex-start;
  }

  .social-icon {
    display: block;
    &.icon-youtube {
      margin-top: 2px;
    }
    &.icon-linkedin {
      margin-top: -2px;
    }
  }
}

.social-icon__item {
  margin: 0 5px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .link {
    padding: 10px;
    display: block;
  }
}

.social-icon {
  transition: all $time-fast ease;
  color: $land-bottom-clickable-elements;
  font-size: 16px;
}
