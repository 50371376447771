
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $land-background;
}
.home-main {
  @extend %w-container-core;
  flex: 1;
  max-width: 1440px;
  width: 100%;
}
.home-faq {
  padding-top: 50px;
  padding-bottom: 50px;

  @include media(">tablet") {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
%w-container-core {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  @include media(">tablet") {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.w-container {
  @extend %w-container-core;
  @include media(">desktop") {
    max-width: 1440px;
  }
}
.w-container--full {
  @extend %w-container-core;
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
}
.w-container--no-padding {
  width: 100%;
}
