// library variables
@import "~@takamol/nawa-library/src/assets/scss/variables";

// app variables
$time-fast: 0.2s;
$time-medium: 0.3s;
$time-slow: 0.6s;
$time-loader: 2s;

$transition-duration: $time-medium;
$transition-property: all;
$transition-timing-function: ease;

$header-shadow: 0 2px 4px 0 rgba(#000, 0.2);

// Landing
$land-background: #f1f4f4;
$land-bottom-clickable-elements: #7c8889;
