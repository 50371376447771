
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.ham-menu {
  cursor: pointer;
  transition: transform 400ms;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: z(panel);
  transform: translateY(-50%);
}
.hamRotate.active {
  transform: translateY(-50%) rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, stroke 500ms;
  stroke: $white;
  stroke-width:4.5;
  stroke-linecap:round;
}
.vue-fixed-header--isFixed .w-header .logo-img .cls-1 {
  fill: #0F5565;
}
.vue-fixed-header--isFixed {
  .ham-menu .line {
    stroke: $clickable-elements;
  }
}
.ham-menu .top {
  stroke-dasharray: 40 139;
}
.sticky-outer-wrapper.open {
  .w-header__mobile {
    box-shadow: none;
    background: transparent;
  }
  .w-header__mobile-logo {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }
}
.ham-menu .bottom {
  stroke-dasharray: 40 180;
}
.ham-menu.active .line {
  stroke: $clickable-elements;
}
.ham-menu.active .top {
  stroke-dashoffset: -98px;
}
.ham-menu.active .bottom {
  stroke-dashoffset: -138px;
}

%btn-reset {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.w-header {
  display: flex;
  align-items: center;
}

.sticky-outer-wrapper {
  height: 80px;

  .has-hero & {
    height: 0;
  }
}

.sticky-inner-wrapper {
  width: auto !important;
  position: static !important;
}

.w-header__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media(">laptop") {
    flex-direction: row;
    justify-content: space-between;
  }
}

.w-header__bar {
  position: fixed;
  top: -100vh;
  right: 0;
  width: 100%;
  height: 100vh;
  background: $white;
  box-shadow: -5px 0 4px 0 rgba(0, 0, 0, 0.1);
  z-index: z(base) + 1;
  transition: top .3s ease;

  @include media(">laptop") {
    box-shadow: $header-shadow;
    width: 100%;
    height: 80px;
    right: 0;
    left: 0;
    top: 0;

    .has-hero & {
      position: absolute;
      background: transparent;
      box-shadow: none;
    }

    .vue-fixed-header--isFixed & {
      position: fixed;
      box-shadow: $header-shadow;
      background: $white;
    }
  }
}

.w-header__bar--opened {
  top: 0;
}

.w-header__info {
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;
  margin-top: 50px;

  @include media(">laptop") {
    justify-content: flex-start;
    order: 1;
    margin-top: 0;
  }
}

.w-header .logo-img .cls-1 {
  fill: $white;
}

.w-header__logo {
  display: none;
  position: relative;
  margin-left: 0;

  @include media(">laptop") {
    display: block;
  }
}

.w-header__logo-img {
  transition: all $time-medium ease;
  max-width: 100%;
  max-height: 35px;
  opacity: 1;

  &.hero-logo {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .has-hero & {
    opacity: 0;

    &.hero-logo {
      opacity: 1;
    }
  }

  .vue-fixed-header--isFixed & {
    opacity: 1;

    &.hero-logo {
      opacity: 0;
    }
  }

  @include media(">laptop") {
    max-height: 50px;
  }
}

.w-header__languages {
  @include media(">laptop") {
    display: none;
  }
}

.w-header__nav {
  order: 1;
  display: flex;

  @include media(">laptop") {
    order: 2;
  }

  .btn-group {
    .btn--large {
      @include media(">tablet") {
        min-width: 135px;
        min-height: 37px;
        font-size: 14px;
        padding: 10px 20px;
      }
    }
  }

  ul {
    li {
      a {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .user-menu {
    margin-left: 30px;
    padding-top: 7px;
    background: transparent;

    .user-menu-profile {
      background: transparent;
      color: $white;
      font-weight: 400;
    }
    .user-menu-label {
      color: white;
      &:hover {
        color: $clickable-elements;
      }
    }

    .user-menu-wrap {
      .user-menu-list {
        border-radius: 0;
        background: transparent;

        .user-menu-btn {
          color: $white;
          font-weight: 400;
        }
      }
    }
  }

  .vue-fixed-header--isFixed & {
    background: $white;

    .user-menu-profile {
      background: $white;
      color: $headers-and-simple-text;

      &:hover {
        color: $clickable-elements;
      }
    }
    .user-menu-label {
      color: $headers-and-simple-text;
      &:hover {
        color: $clickable-elements;
      }
    }

    .user-menu-wrap {
      .user-menu-list {
        background: $white;


        .user-menu-btn {
          color: $headers-and-simple-text;
        }
      }
    }
  }
}

.w-header__mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  box-sizing: border-box;
  box-shadow: $header-shadow;
  margin: 10px 10px 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
  background: $white;
  z-index: z(base) + 2;

  .has-hero & {
    background: transparent;
    box-shadow: none;
  }

  .vue-fixed-header--isFixed & {
    box-shadow: $header-shadow;
    background: $white;
  }

  @include media(">laptop") {
    display: none !important;
  }
}

.w-header__mobile-logo .logo-text-path {
  fill: #fff;
}
.w-header__mobile-logo {
  position: relative;
}

.w-header__mobile-btn {
  @extend %btn-reset;
  color: $clickable-elements;
  font-size: 18px;
  cursor: pointer;
  padding: 2px;

  .has-hero & {
    color: $white;
  }

  .w-header__bar--opened &,
  .vue-fixed-header--isFixed & {
    color: $clickable-elements;
  }

  @include media(">laptop") {
    display: none;
  }
}

.w-header__mobile-btn--close {
  position: absolute;
  top: 30px;
  right: 35px;
}

// IE 11
:-ms-fullscreen,
:root {
  .w-header__bar {
    @include media(">laptop") {
      position: relative;
    }
  }

  .w-header__info {
    position: absolute;
    left: 50px;
    top: 15px;
  }
}

// nav
.w-nav__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;

  @include media(">laptop") {
    flex-direction: row;
    justify-content: flex-start;
  }
}

.w-nav__item {
  position: relative;
  margin-bottom: 30px;
  color: $headers-and-simple-text;

  @include media(">laptop") {
    margin-right: 30px;
    margin-bottom: 0;

    .has-hero & {
      color: $white;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.w-nav__item--logout {
  @include media(">laptop") {
    display: none;
  }
}

.w-nav__item--dropdown {
  display: none;
  margin-left: -20px;

  @include media(">laptop") {
    display: block;
    padding: 25px 0;
  }

  @include media(">desktop") {
    padding: 18px 0;
  }
}

.w-nav__item,
.w-nav__link {
  color: $headers-and-simple-text;
  transition: all $time-medium ease;

  &::after {
    content: "";
    position: absolute;
    right: 55%;
    bottom: -2px;
    left: 55%;
    height: 1px;
    transition: all $time-medium ease;
    background-color: $clickable-elements;
  }

  &.is-active {
    &::after {
      right: 0;
      left: 0;
    }
  }

  &.is-active,
  .is-active &,
  &:hover {
    color: $clickable-elements;
  }

  @include media(">laptop") {
    .has-hero & {
      color: $white;
    }

    .vue-fixed-header--isFixed & {
      color: $headers-and-simple-text;

      &.is-active,
      .is-active &,
      &:hover {
        color: $clickable-elements;
      }
    }
  }
}

.w-nav__item--underline {
  color: $line-on-background-dark-or-gap;

  &::after {
    right: 0;
    left: 0;
    background-color: currentColor;
  }

  &:hover::after {
    transition: all $time-medium ease;
    background-color: transparent;
  }

  .w-nav__link {
    color: $description-text;
    transition: all $time-medium ease;

    &:hover {
      color: $clickable-elements;
    }
  }

  @include media(">laptop") {
    .has-hero & {
      color: $white;
    }

    .w-nav__link {
      .has-hero & {
        color: $white;

        &:hover {
          color: $white;
        }
      }

      .vue-fixed-header--isFixed & {
        color: $description-text;

        &:hover {
          color: $clickable-elements;
        }
      }
    }
  }
}

.w-header__languages {
  margin: 20px 0;

  @include media(">laptop") {
    margin: 15px 0;
  }

  .language {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .language__item {
      @include transition;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      padding: 5px 10px;
      border-radius: 21px;
      background: $white;
      color: $clickable-elements;
      font-size: 12px;
      border: none;
      width: 75px;
      height: 25px;
      outline: none;
      @include media("<=laptop") {
        outline: none;
      }
      &:focus-visible {
        outline: auto;
        transition: none;
      }
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.is-active,
      &:hover {
        background-color: $clickable-elements;
        color: $white;
      }
    }
  }
}
