
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.home-hero {
  position: relative;
  min-height: 366px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  text-align: center;
  color: $white;
  padding: 90px 15px 15px;

  @include media("<tablet") {
    min-height: 667px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: z(base) - 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #152e30, $alpha: .5);
  }

  &__inner {
    position: relative;
    z-index: z(base);
  }

  &__heading {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 600;
    margin: 0 0 22px;

    @include media("<tablet") {
      font-size: 34px;
    }
  }

  &__text {
    font-size: 21px;
    line-height: 1.2;
    font-weight: 500;
    margin: 0;
    color: #d1d3d0;

    @include media("<tablet") {
      font-size: 17px;
    }
  }
}
